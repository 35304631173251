<template>
  <div class="activity" v-if="list.length > 0">
    <p class="title">活动中心</p>
    <ul class="ul-list">
      <li>
        <div class="list-item" v-for="item in list" :key="item.id" @click="jumper(item.id)">
          <img :src="item.bannerRectangle">
          <p><img src="../../../assets/createClock.png">{{$timestampToString(item.signStartTime)}}</p>
        </div>
      </li>
      <li @click="$router.push('/activity')">更多活动</li>
    </ul>
  </div>
</template>

<script>
import {activity_list} from "@/api/index";

export default {
  name: "activity",
  data(){
    return {
      list:[]
    }
  },
  created() {
    this.activityfn()
  },
  methods: {
    activityfn() {
      activity_list({pageNum:1,pageSize:4,type:1} ).then(
          (res) => {
            this.list=res.data.list
          }
      )
    },
    jumper(id){
      localStorage.removeItem('songdetails')
      window.open('/#/detailssing/'+id)
    }
  }

}
</script>

<style scoped lang="scss">
.activity {
  padding: 24px;
  box-sizing: border-box;
  margin-top: 20px;
  height: 226px;
  background: #ffffff;
  border-radius: 4px;

  > p.title {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #333333;
  }

  > .ul-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    > li:nth-child(1) {
      display: flex;
      padding-top: 20px;
      box-sizing: border-box;
      width: 90%;

      > .list-item {
        margin-right: 1%;
        font-size: 0;
        width: 25%;
        > img {
          width: 100%;
          height: 103px;
          border-radius: 8px;
        }

        p {
          margin: 13px 0 0 0;
          padding: 0;
          font-size: 10px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #bbbbbb;

          img {
            width: 10px;
            height: 10px;
            margin-right: 6px;
          }
        }
      }
    }

    li:nth-child(2) {
      width: 36px;
      height: 80px;
      background: #202020;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #ffffff;
      writing-mode: tb-rl;
      line-height: 36px;
      text-align: center;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
      margin-right: -24px;
      cursor: pointer;
    }
  }
}
</style>
