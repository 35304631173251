<template>
  <div class="nav"  v-if="needToDeDealtWith.type && needToDeDealtWith.type !== 9 && managerUser">
    <div class="left">
      <img class="first" src="../../../assets/matter-img.png">
      <div>待办事项</div>
      <span>|</span>
      <p>{{toDoNode}}</p>
      <el-tooltip class="item" effect="dark"  v-show="[2,3,5,6].includes(needToDeDealtWith.type)" placement="bottom">
        <div slot="content" class="tips">
          {{needToDeDealtWith.msg}}
        </div>
        <img class="last" src="../../../assets/tips-img.png">
        </el-tooltip>
    </div>
    <div class="right">
      <button @click="modifyFn()">{{jumpCopy}}</button>
    </div>
  </div>
</template>

<script>
import {todoListTop} from "@/api/index";

export default {
  name: "matter",
  data(){
    return {
      needToDeDealtWith:{
      }
    }
  },
  /**
   * 0.用户未认证,暂无待办;
   * 1.审核通过，未发布任何作品;
   * 2.有歌曲审核未通过
   * 3.有词曲审核未通过;
   * 4.有待确认账单;
   * 5.银行卡审核未通过;
   * 6.提现申请审核未通过;
   * 7.有作业未提交;
   * 8.暂无以上待办事项
  *
  * */
  computed:{
    toDoNode(){
      let title = ''
      switch (this.needToDeDealtWith.type) {
        case 1 :
          title = '您还未发布音乐作品！立即发布'
          break
        case 2 :
          title = '您有歌曲作品审核未通过!'
          break
        case 3 :
          title = '您有词曲作品审核未通过'
          break
        case 4 :
          title = `您有${this.needToDeDealtWith.msg}首歌曲的分轨制作文件待上传!`
          break
        case 5 :
          title = '您有账单待确认'
          break
        case 6 :
          title = '您的银行卡审核未通过'
          break
        case 7 :
          title = '您有提现申请未通过!'
          break
        case 8:
          title = '您有作业未提交'
      }
      return title
    },
    userMsg(){
      return this.$store.state.usermsg
    },
    managerUser(){
     return !(this.userMsg.webLoginType === 1 && this.userMsg.userCompanyVo.managerUser === 0)
    },
    jumpCopy(){
      let title = ''
        if(this.needToDeDealtWith.type === 1){
          title = '发布作品'
        } else if ([2,3,5,6].includes(this.needToDeDealtWith.type)){
           title = '修改'
        } else {
          title = '查看'
        }
      return title
    }
  },
  mounted() {
    this.matterFn()
  },
 methods:{
   matterFn(){
     todoListTop().then(res=>{
       if(res.code === 0 && res.data !== null){
           this.needToDeDealtWith = res.data
       }
     })
   },
   modifyFn(){
     let type = this.needToDeDealtWith.type
     if(type === 1){
        if(this.userMsg.webLoginType === 1 && this.userMsg.userCompanyVo.companyType === 1){
          this.$router.push('/issue')
      }else {
          this.$router.push('/release/0')
        }
     }else if(type === 2){
       if(this.userMsg.webLoginType === 1 && this.userMsg.userCompanyVo.companyType === 1){
         this.$router.push('/applyList');
       }else {
         this.$router.push('/song-manage')
       }
     }else if(type === 3){
       this.$router.push('/demo-list')
     }else if(type === 4){
       this.$router.push('/songUploadRecord/0')
     }else if(type === 5){
       this.$router.push('/confirm')
     }else if(type === 6){
       this.$router.push('/setbankcard/Msmchecking/'+ this.$store.state.usermsg.webLoginType)
     }else if(type === 7){
       this.$router.push('/cashing-records')
     }else if(type === 8){
       this.$router.push('/taskrun')
     }
   }
 }
}
</script>

<style scoped lang="scss">
.nav {
  padding: 0 30px 0 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  list-style: none;
  min-height: 60px;
  background: #ffeecd;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;

    .first {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }

    > div, span {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: LEFT;
      color: #996c36;
    }

    > span {
      margin: 0 3px;
    }

    > p {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 400;
      text-align: LEFT;
      color: #996c36;
      msx-width: 600px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
    }

     .last {
      width: 14px;
      height: 14px;
      margin-left: 6px;
    }
  }

  .right {
    button {
      width: 68px;
      height: 28px;
      background: #ffffff;
      border-radius: 2px;
      border: none;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #af6d00;
      cursor: pointer;
    }
  }
}
.tips{
  padding: 12px 14px;
  p{
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #ffffff;
  }
  p:nth-child(1){
    margin-bottom: 6px;
  }
}
</style>
