<template>
<div class="big">
  <div class="l">
    <p>音乐人权益</p>
    <ul>
      <li v-for = "item in list" :key="item.id">
        <img :src = "item.picUrl">
        <p>{{item.name}}</p>
      </li>
    </ul>
  </div>
  <div class="r" v-if="info.length > 0">
    <div class="header-title">
       <div>资讯</div>
      <div @click="$router.push('/consulthome')">更多></div>
    </div>
    <ul class="content">
      <li v-for="item in info" :key="item.id" @click="$router.push({path:'/details',query:{id:item.id}})">
        <img :src="item.coverBanner">
        <div class="details">
          <p>{{item.title}}</p>
          <div>
             <img src="../../../assets/timer.png">
             <span>{{$timestampToString(item.releaseTime)}}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="r" v-else>
    <div class="header-title">
      <div>资讯</div>
    </div>
    <div class="content">
      <img src="../../../assets/Informationdefault.png">
    </div>
  </div>
</div>
</template>

<script>
import { homePage } from  '../../../api/index'
export default {
  name: "LegalAndInfo" ,
  data(){
    return {
      list:[] ,
      info:[] ,
    }
  },
   created() {
    this.homeMenus()
     this.selected()

   },
  methods:{
    homeMenus(){
      this.$post('/api/dict/homeMenus' , {}).then(res=>{
        this.list = res.data.list ;
      })
    },
    selected(){
      homePage({pageNum:1,pageSize:3}).then(res => {
        this.info = res.data ;
        this.info.length = 3 ;
      })
    }
  }
}
</script>

<style scoped lang="scss">
*{
  padding: 0;
  margin: 0;
  list-style: none;
}
.big{
  height: 332px;
  display: flex;
  margin-top: 20px;
  >.l{
    width: 49.5%;
    margin-right: 1%;
    background: #FFFFFF;
     border-radius: 4px;
    >p{
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      margin-top:24px ;
      margin-bottom:43px;
      padding-left: 32px;
    }
    >ul{
      display: grid;
      grid-template-columns: repeat(4,54px);
      grid-row-gap: 30px;
      grid-column-gap: 18%;
      padding: 0 53px;
     li{
       img{
         width: 100%;
         height: 54px;
       }
       p{
         font-size: 12px;
         font-family: PingFang SC, PingFang SC-Regular;
         font-weight: 400;
         text-align: center;
         color: #666666;
       }
     }
    }
  }
  >.r{
    width: 49.5%;
    background: #FFFFFF;
    padding: 24px 30px 0 26px;
    box-sizing: border-box;
    border-radius: 4px;
    .header-title{
      display: flex;
      justify-content: space-between;
      div:nth-child(1){
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }
      div:nth-child(2){
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: RIGHT;
        color: #ff004d;
        cursor: pointer;
      }
    }
    div.content{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      img{
        width: 84px;
        height: 84px;
      }
    }
    .content{
      >li{
        margin-top: 20px;
        display: flex;
        cursor: pointer;
        img{
          width: 70px;
          height: 64px;
          margin-right:16px;
        }
        .details{
             width: 100%;
          p{
            width: 95%;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #666666;
            margin-bottom: 6px;
          }
          div{
            img{
              width: 10px;
              height: 10px;
              margin-right: 6px;
            }
            span{
              font-size: 10px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #bbbbbb;
            }
          }
        }
      }
    }
  }

}
</style>
