<template>
  <div class="works">
    <left-menu/>
    <div class="main">
      <header class="header">
        <div class="left">
          <div class="image">
            <img :src="userFn.avatar">
          </div>
          <ul>
            <li class="title_name">{{ userFn.name }}</li>
            <li class="identity"><img
                src="../../assets/examine/authen2.png">{{userMsg.webLoginType === 0 ? (userMsg.userTreaty || '合作音乐人') : userMsg.userCompanyVo ? userMsg.userCompanyVo.companyType === 1 ? '合约机构':'认证机构' :'' }}
            </li>
            <li class="edit"></li>
          </ul>
        </div>
        <div class="right">
          <div class="opus">
            <div class="l">
              <p class="title">已发行作品数</p>
              <p class="content">{{ userMsg.songNums }}</p>
            </div>
            <div>
              <p class="title">已售词曲</p>
              <p class="content">{{ userMsg.demoNums }}</p>
            </div>
          </div>
          <div class="money">
            <div class="r">
              <p class="title">累计收益</p>
              <p class="content">{{ $toFindexFn(allMoney) }}</p>
            </div>
          </div>
        </div>
      </header>
      <Matter></Matter>
<!--      <user-echart :userObj="userMsg"></user-echart>-->
      <Activity></Activity>
      <Legal-and-info></Legal-and-info>
    </div>
  </div>
</template>

<script>
/**
 * user
 * 创作中心
 */
import leftMenu from '../../components/LeftMenu'
import Matter from './components/matter'
import Activity from "./components/Activity";
import LegalAndInfo from "./components/LegalAndInfo"
import {detailWallet} from '../../api/income'

export default {
  name: "Index",
  components: {
    Activity,
    leftMenu,
    Matter,
    LegalAndInfo
  },
  computed: {
    userMsg() {
      return this.$store.state.usermsg || {}
    },
    userFn() {
      if (this.userMsg.webLoginType === 0) {
            if( this.userMsg.name !== undefined){
              return {
                name:this.userMsg.name,
                avatar:this.userMsg.avatar,
              }
            }

      } else {
          if(this.userMsg.name !== undefined){
            return {
              name:this.userMsg.userCompanyVo.name,
              avatar:this.userMsg.userCompanyVo.logo,
            }
          }
        }
      return {}
    }
  },
  data() {
    return {
      allMoney: 0,
    }
  },
  created() {
    this.wallet_detail()
  },
  methods: {
    async wallet_detail() {
      let res = await detailWallet()
      if (res.code === 0) {
        this.allMoney = res.cashOutData.allMoney
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/works.scss';
.main {
  background: #f7f7f7;
  padding: 0;
  border: none;

  > .header {
    background: #FFFFFF;
    height: 170px;
    margin-bottom: 20px;
    width: 100%;
    padding: 0 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;

    > .left {
      margin: 0;
      display: flex;
      align-items: center;

      > .image {
        width: 90px;
        height: 90px;
        margin-right: 20px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
        }
      }

      > ul {
        list-style: none;
        padding: 0;
        margin: 0;

        .title_name {
          font-size: 20px;
          font-family: PingFang SC, PingFang SC-Semibold;
          font-weight: 600;
          text-align: LEFT;
          color: #333333;
          letter-spacing: 0px;
        }

        .identity {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #ff004d;
          letter-spacing: 0px;
          padding: 4px 8px;
          background: rgba(255, 0, 77, 0.05);
          height: 25px;
          width: 101px;
          line-height: 19px;
          margin: 8px 0;
          box-sizing: border-box;

          img {
            width: 14px;
            height: 14px;
            vertical-align: middle;
            margin-right: 6px;
          }
        }

        .edit {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #666666;
          letter-spacing: 0px;
        }
      }
    }

    > .right {
      display: flex;
      align-items: center;
      .title {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #666666;
        letter-spacing: 0px;
        margin: 0;
        padding: 0;
      }

      .content {
        margin: 0;
        padding: 0;
        font-size: 24px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: LEFT;
        color: #333333;
        letter-spacing: 0px;
      }

      > .opus {
        width: 275px;
        height: 90px;
        display: flex;
        align-items: center;
        background: #f9f9f9;
        padding-left: 34px;
        box-sizing: border-box;

        .l {
          margin-right: 54px;
        }
      }

      > .money {
        width: 188px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f9f9f9;
        margin-left: 21px;
      }
    }
  }
}

</style>
